<template>
  <div class="logo-header">
    <div class="logo-header-container">
      <div class="left">
        <cc-iconfont name="dm" size="28" color="#222222"/>
        <div class="login-type">
          {{ TYPES[type] }}
        </div>
      </div>
      <div v-if="type!=='login'" class="right">
        <div class="login">
          已有账号，
          <a @click="goLogin">马上登录</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CCLogoHeader',
  props: {
    type: {
      type: String,
      default: 'login'
    }
  },
  data() {
    return {
      TYPES: {
        register: '注册',
        login: '登录',
        reset: '找回密码'
      }
    };
  },
  methods: {
    goLogin() {
      this.$router.push({ name: 'Login' });
    }
  }
};
</script>

<style lang="less" scoped>
.logo-header {
  display: flex;
  align-items: center;
  margin: 0 105px;
  height: 80px;
  justify-content: space-between;
  position: relative;

  .logo-header-container {
    width: 1000px;
    margin: 0 auto;
    position: relative;
  }

  .login-type {
    color: #555555;
  }

  .left {
    display: flex;
    align-items: center;

    div {
      font-size: 24px;
      font-weight: bold;
      border-left: 1px solid #DADADA;
      padding-left: 20px;
      margin-left: 20px;
    }
  }

  .right {
    position: absolute;
    right: 0;
    bottom: -6px;

    .login {
      font-size: 14px;

      a {
        font-size: 14px;
      }

      span {
        color: @primary-color;
      }
    }
  }
}
</style>
